import React, { useState, useEffect, useRef } from "react"
import { getAlignment } from "../../../functions/utils/get-alignment"

import "./ImageRow.scss"

const GifRow = ({ data }) => {
  const gifContainer = useRef(null)
  const [gifWidth, setGifWidth] = useState(0)
  useEffect(() => {
    const tTimeout = setTimeout(() => {
      setGifWidth(gifContainer.current?.offsetWidth)
    }, 200)
    return () => {
      clearTimeout(tTimeout)
    }
  }, [])

  return (
    <div className="image-row-container module-btm-margin">
      <div
        ref={gifContainer}
        className={`single-image ${getAlignment(data.singleImage.alignment)}`}
      >
        <img
          style={{ width: gifWidth }}
          src={data.singleImage.image?.localFile.url}
          alt=""
        />
      </div>
    </div>
  )
}

export default GifRow
