import React from "react"
import "./SimpleText.scss"

const SimpleText = ({ data }) => {
  return (
    <div className="simple-text">
      <div
        dangerouslySetInnerHTML={{
          __html: `${data.text}`,
        }}
      />
    </div>
  )
}

export default SimpleText
