import React from "react"
import { getAlignment } from "../../../functions/utils/get-alignment"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import "./ImageRow.scss"

const ImageRow = ({ data }) => {
  return (
    <div className="image-row-container module-btm-margin">
      {data.type === "1" ? (
        <div
          className={`single-image ${getAlignment(data.singleImage.alignment)}`}
        >
          {getImage(data.singleImage.image?.localFile) !== undefined && (
            <GatsbyImage
              image={getImage(data.singleImage.image?.localFile)}
              alt=""
            />
          )}
        </div>
      ) : (
        <div className="multi-images">
          {data.twoImages?.map((image, index) => {
            return (
              <div key={`image-${index}`}>
                {getImage(image.image?.localFile) !== undefined && (
                  <GatsbyImage
                    key={`multi_image_${index}`}
                    image={getImage(image.image?.localFile)}
                    alt=""
                  />
                )}
              </div>
            )
          })}
        </div>
      )}
    </div>
  )
}

export default ImageRow
