import React from "react"
import Vimeo from "@u-wave/react-vimeo"
import "./VideoRow.scss"

const VideoRow = ({ data }) => {
  return (
    <div className="vimeo-video-module">
      <section className="video-row">
        <Vimeo
          id="vimeo-player"
          video={data.vimeoId}
          responsive={true}
          loop={true}
          controls={true}
          dnt={true}
        />
      </section>
    </div>
  )
}

export default VideoRow
