import React from "react"
import { LayoutNames } from "../../../functions/utils/layout-name-extractor"

import SimpleText from "../../Modules/SimpleText/SimpleText"
// import VideoBox from "../../Modules/VideoBox/VideoBox"
import VideoRow from "../../Modules/VideoRow/VideoRow"
import GifRow from "../../Modules/ImageRow/GifRow"
import ImageRow from "../../Modules/ImageRow/ImageRow"

const ProjectLayout = ({ layout }) => {
  let layoutModules = []
  if (layout !== null) {
    layoutModules = LayoutNames(layout)
  }

  return (
    <>
      {layout !== null ? (
        layoutModules.map((layoutComponent, i) => {
          return {
            VideoBox: <VideoRow key={`module_${i}`} data={layout[i]} />,
            Image: <ImageRow key={`module_${i}`} data={layout[i]} />,
            Gif: <GifRow key={`module_${i}`} data={layout[i]} />,
            SimpleText: <SimpleText key={`module_${i}`} data={layout[i]} />,
          }[layoutComponent]
        })
      ) : (
        <div>
          <span>NO LAYOUT SELECTED</span>
        </div>
      )}
    </>
  )
}
export default ProjectLayout
