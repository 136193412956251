import React from "react"
import { graphql } from "gatsby"
import ProjectLayout from "../../components/Layouts/SingleProject/SingleProjectLayout"
import Seo from "../../components/Seo"

const SingleProjectPage = ({ data }) => {
  const { wpPost } = data

  return (
    <>
      <Seo title={wpPost.title} />
      <div className="article-container">
        <ProjectLayout layout={wpPost.project.projectLayout} />
      </div>
    </>
  )
}

export default SingleProjectPage

export const query = graphql`
  query ($postId: String!) {
    wpPost(id: { eq: $postId }) {
      id
      title
      project {
        projectLayout {
          ...Layout
        }
      }
    }
  }
  fragment Layout on WpPost_Project_ProjectLayout {
    ... on WpPost_Project_ProjectLayout_VideoBox {
      fieldGroupName
      vimeoId
    }
    ... on WpPost_Project_ProjectLayout_SimpleText {
      fieldGroupName
      text
    }
    ... on WpPost_Project_ProjectLayout_Gif {
      fieldGroupName
      singleImage {
        fieldGroupName
        image {
          localFile {
            url
          }
        }
      }
    }
    ... on WpPost_Project_ProjectLayout_Image {
      fieldGroupName
      type
      singleImage {
        alignment
        image {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, formats: [WEBP, AUTO])
            }
          }
        }
      }
      twoImages {
        image {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, formats: [WEBP, AUTO])
            }
          }
        }
      }
    }
  }
`
