export const getAlignment = choice => {
  let out = ""
  switch (choice) {
    case "1":
      out = "center"
      break
    case "2":
      out = "left"
      break
    case "3":
      out = "right"
      break
    default:
      out = "center"
      break
  }
  return out
}
